
const USER_STORAGE_KEY = 'USER_INFO'
const ROUTE_STORAGE_KEY = 'DEFAULT_ROUTE'
// sessionStorage 存储用户信息
export function getToken() {
  let userInfo = sessionStorage.getItem(USER_STORAGE_KEY)
  if (userInfo) {
    return JSON.parse(userInfo).token
  } else {
    return userInfo
  }
}
export function setUserInfoForStorage(data) {
  sessionStorage.setItem(USER_STORAGE_KEY, data)
}
export function getUserInfoForStorage() {
  let userInfo = sessionStorage.getItem(USER_STORAGE_KEY)
  return userInfo ? JSON.parse(userInfo) : ''
}
export function removeUserInfo() {
  sessionStorage.removeItem(USER_STORAGE_KEY)
}

// sessionStorage 存储首次路由信息
export function setDefaultRouteForStorage(data) {
  sessionStorage.setItem(ROUTE_STORAGE_KEY, data)
}
export function getDefaultRouteForStorage() {
  let defaultRoute = sessionStorage.getItem(ROUTE_STORAGE_KEY)
  return defaultRoute ? JSON.parse(defaultRoute) : ''
}