import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: null,
    cacheRoutes: [],
    // 缓存页面是否在编辑状态  key值必须为组件名称
    pageEditStatus: {
      notification: false,
      illegal: false,
      complaint: false,
      governance: false
    },
    // 各页面按钮权限信息
    btnPrivilegeCode: {},
    overSpeedList: []
  },
  mutations: {
    setUserInfo: (state, userInfo) => {
      state.userInfo = userInfo
    },
    setCacheRoutes: (state, cacheRoutes) => {
      state.cacheRoutes = cacheRoutes
    },
    setPageEditStatus: (state, pageEditStatus) => {
      state.pageEditStatus = pageEditStatus
    },
    setBtnPrivilegeCode: (state, btnPrivilegeCode) => {
      state.btnPrivilegeCode = btnPrivilegeCode
    },
    setOverSpeedList: (state, overSpeedList) => {
      state.overSpeedList = overSpeedList
    }
  },
  actions: {
  },
  getters: {
    getOverSpeedList: (state) => state.overSpeedList
  },
  modules: {
  }
})
