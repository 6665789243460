
import _vue from '@/main.js'
import {removeUserInfo} from '@/assets/js/sessionStorage.js'
// 登出函数
export function logout () {
  removeUserInfo()
  // 清除routes缓存
  _vue.$store.dispatch('setCacheRoutes', [])
  if (_vue.$route.path.includes('login')) {
    // 如果是登录界面则不跳转
    return
  }
  _vue.$Modal.warning({
    title: '权限错误',
    content: '用户验证失败,请重新登录!',
    onOk: () => {
      _vue.$router.push('/login')
    }
  })
}

// // 获取该页权限信息
// export function getCurrentPagePrivilegeInfo(pageCode) {
//   let menuInfo = getUserInfoForStorage().menus.menuViews
//   let currentPagePrivilegeInfo = this.getChildrenMenuInfo(menuInfo, pageCode)
//   return currentPagePrivilegeInfo.operations.map(item => item.code)
// },
// function getChildrenMenuInfo (menuInfo, pageCode) {
//   let currentPagePrivilegeInfo = null
//   menuInfo.forEach(menuItem => {
//     if (menuItem.code === pageCode) {
//       currentPagePrivilegeInfo = menuItem
//     } else {
//       if (menuItem.children.length > 0) {
//         currentPagePrivilegeInfo = this.getChildrenMenuInfo(menuItem.children, pageCode)
//       }
//     }
//   })
//   return currentPagePrivilegeInfo
// }

// 事件对象转string
export function getTimeStrByType (objectTime, type) {
  if (typeof(objectTime) == 'string') {
    return objectTime
  }
  if (type == '0') {
    // 按秒
    return `${objectTime.getFullYear()}-${addZero(objectTime.getMonth() + 1)}-${addZero(objectTime.getDate())} ${addZero(objectTime.getHours())}:${addZero(objectTime.getMinutes())}:${addZero(objectTime.getSeconds())}`
  } else if (type == '1') {
    // 按分
    return `${objectTime.getFullYear()}-${addZero(objectTime.getMonth() + 1)}-${addZero(objectTime.getDate())} ${addZero(objectTime.getHours())}:${addZero(objectTime.getMinutes())}`
  } else if (type == '2') {
    // 按时
    return `${objectTime.getFullYear()}-${addZero(objectTime.getMonth() + 1)}-${addZero(objectTime.getDate())} ${addZero(objectTime.getHours())}`
  } else if (type == '3') {
    // 按天
    return `${objectTime.getFullYear()}-${addZero(objectTime.getMonth() + 1)}-${addZero(objectTime.getDate())}`
  } else if (type == '4') {
    // 按月
    return `${objectTime.getFullYear()}-${addZero(objectTime.getMonth() + 1)}`
  } else if (type == '5') {
    // 按月 201901 
    return `${objectTime.getFullYear()}${addZero(objectTime.getMonth() + 1)}`
  }
}

// 补零函数
export function addZero (time) {
  return time >= 10 ? time : `0${time}`
}

// 范围是否合法
export function isValidRange (num, min, max) {
  min = isEmpty(min) ? min : parseFloat(min)
  max = isEmpty(max) ? max : parseFloat(max)
  return (!isEmpty(min) && !isEmpty(max) && min <= max && (num >= min && num <= max)) ||
  (!isEmpty(min) && !isEmpty(max) && min > max && (num >= max && num <= min)) ||
  (isEmpty(min) && num <= max) || (isEmpty(max) && num >= min) ||
  (isEmpty(min) && isEmpty(max))
}

// 是否为空
export function isEmpty (value) {
  return value === '' || value === null || value === undefined ||
  (typeof value === 'object' && (JSON.stringify(value) === '{}' || JSON.stringify(value) === '[]'))
}

// 比较两个数的大小
export function isAmoreB (A, B) {
  const a = isEmpty(A) ? 0 : parseFloat(A)
  const b = isEmpty(B) ? 0 : parseFloat(B)
  return a > b
}