import axios from 'axios'
import {getToken} from '@/assets/js/sessionStorage.js'
import {logout} from '@/assets/js/common.js'
let resStatus = true
// 添加请求拦截器
axios.interceptors.request.use(config => {
  // 在发送请求之前做些什么
  config.headers = {
    ...config.headers,
    Authentication: getToken()
  }
  return config
}, function (error) {
  // 对请求错误做些什么
  // console.log(error)
  return Promise.reject(error)
})

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
  if (response.data.code === 401 && resStatus) {
    // 确保只有一条错误请求触发登出事件
    resStatus = false
    // token过期重新登录
    logout()
  } else if (response.data.code !== 401) {
    resStatus = true
  }
  // 对响应数据做点什么
  return response
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error)
})
