import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/common.scss'
import '@/assets/js/axios.js'
import '@/assets/js/iconfont.js'
import Print from '@/assets/js/print.js'
import ViewUI from 'view-design'
import 'view-design/dist/styles/iview.css'
import { DatePicker, Button, Input, Option, Select } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import { getToken, setDefaultRouteForStorage } from '@/assets/js/sessionStorage.js'
import axios from 'axios'
Vue.use(Print)
Vue.use(ViewUI)
Vue.use(DatePicker)
Vue.component(Button.name, Button)
Vue.component(Input.name, Input)
Vue.component(Select.name, Select)
Vue.component(Option.name, Option)
Vue.config.productionTip = false
// let isFirstEnterSys = false
// 路由全局守卫
// router.beforeEach(async (to, from, next) => {
//   if (isFirstEnterSys) {
//     next()
//   } else {
//     isFirstEnterSys = true
//     if (getToken()) {
//       // 如果用户token存在则直接跳转
//       if (to.path != '/home') {
//         // 只要不是加载首页 就存储第一次加载路由
//         setDefaultRouteForStorage(JSON.stringify(to))
//       } else {
//         setDefaultRouteForStorage('')
//       }
//       next({path: '/home'})
//     } else {
//       setDefaultRouteForStorage('')
//       next({path: '/login'})
//     }
//   }
// })
router.beforeEach(async (to, from, next) => {
  try {
    if (!WEB_CONFIG.isGetParams) {
      let _URL = window.location.hostname
      let _port = window.location.port ? window.location.port : '80'
      // 如果获取到配置则跳过
      // 正式服
      let { data } = await axios(`http://zfpt.vcolco.com:28086/gov-public/SysConfig/getConfig/v2/${_URL}/${_port}`)
      // 测试服
      // let { data } = await axios(`http://zfpt.vcolco.com:28086/gov-public/SysConfig/getConfig/v2/10.50.125.62/10000`)
      WEB_CONFIG = {
        BASE_IP: data.data.CHECK_IP,
        ...WEB_CONFIG,
        ...data.data,
      }
      WEB_CONFIG.isGetParams = true
      if (getToken()) {
        // 如果用户token存在则直接跳转
        if (to.path != '/home' && to.path != '/login') {
          // 只要不是加载首页或者登录页 就存储第一次加载路由
          setDefaultRouteForStorage(JSON.stringify(to))
        } else {
          setDefaultRouteForStorage('')
        }
        next({ path: '/home' })
      } else {
        setDefaultRouteForStorage('')
        next({ path: '/login' })
      }
    } else {
      next()
    }
  } catch (err) {
    _vue.$Message.error('获取网站配置参数出错,请联系系统管理员!')
  }
})

let _vue = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

export default _vue
